import axiosInstance from "./api";
import TokenService from "@/services/token.service"
import {
  AUTH_LOGOUT,
  AUTH_ERROR,
} from "@/store/actions/auth";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.VUE_APP_ROOT_API + '/api/v1';
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/login/" && err.response) {
        // Access Token was expired
        if (err.response.status === 403 && !originalConfig._retry) {
          originalConfig._retry = true;
          store.dispatch(AUTH_ERROR);
          let status = TokenService.getStatus()
          if (status['loadAuth'] && status['loadAuth'] < 3){
            store.dispatch(AUTH_LOGOUT);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
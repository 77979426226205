import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import setupInterceptors from './services/setupInterceptors';
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'

import "@fortawesome/fontawesome-free/js/brands"
import "@fortawesome/fontawesome-free/js/solid"
import "@fortawesome/fontawesome-free/js/fontawesome"

import 'jquery'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap'
import "@/assets/js/customjs.js"
import "@/assets/scss/custom.scss"


setupInterceptors(store)
const app = createApp(App);

app.use(router)
    .use(store)
    .use(VueUniversalModal, {
        teleportTarget: '#modals'
      })
    .mount('#app');

import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_REFRESH,
  AUTH_RETRY
} from "../actions/auth";
import api from "@/services/api"
import TokenService from "@/services/token.service";
import router from "@/router"

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { status: "success", loggedIn: true, loadAuth: 0 }, user }
  : { status: { status: "", loggedIn: false, loadAuth: 0 }, user: null };

const state = initialState;

const getters = {
  isAuthenticated: state => !!state.user.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);

      let user_storage = TokenService.getUser()
      if (user_storage) dispatch(AUTH_LOGOUT);

      api({ url: "/auth/login/", data: user, method: "POST" })
        .then(res => res.data)
        .then(resp => {
          if (resp && resp.access_token) {
            commit(AUTH_SUCCESS, resp);
          } else {
            reject(resp)
          }
          resolve(resp);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_ERROR]: ({ commit }) => {
    commit(AUTH_ERROR);
  },
  [AUTH_RETRY]: ({ commit }) => {
    commit(AUTH_RETRY);
  },
  [AUTH_REFRESH]: ({ commit }, access_token) => {
    commit(AUTH_REFRESH, access_token);
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      delete api.defaults.headers.common['Authorization']
      // logout
      api({ url: "/auth/logout/", data: user, method: "POST" })

      // commit
      commit(AUTH_LOGOUT);
      TokenService.removeUser();

      resolve();
    });
  }
};

const mutations = {
  [AUTH_RETRY]: () => {
    let status = TokenService.getStatus()
    status['status'] = "relogin";
    status['loggedIn'] = false;
    status['loadAuth'] += 1;
    TokenService.setStatus(status)
  },
  [AUTH_REQUEST]: (state) => {
    let status = { status: "loading", loggedIn: false, loadAuth: 0 };
    state.status = status
    TokenService.setStatus(status)
  },
  [AUTH_REFRESH]: (state) => {
    let status = { status: "success", loggedIn: true, loadAuth: 0 };
    state.status = status
    TokenService.setStatus(status)
  },
  [AUTH_SUCCESS]: (state) => {
    let status = { status: "success", loggedIn: true, loadAuth: 0 };
    state.status = status
    TokenService.setStatus(status)
  },
  [AUTH_ERROR]: (state, error) => {    
    let status = TokenService.getStatus()
    
    if (error && error.message) {
      status['status'] = error.message;
      if (error.message.toLowerCase() == 'request failed with status code 400'){
        status['status'] = "Incorrect email or password."
      }
    } else {
      status['status'] = 'error';
    }
    status['loggedIn'] = false;
    status['loadAuth'] += 1;
    state.status = status
    TokenService.setStatus(status)
  },
  [AUTH_LOGOUT]: () => {
    let status = TokenService.getStatus()
    status['status'] = 'logout'
    status['loggedIn'] = false
    TokenService.setStatus(status)
    router.push('/login')
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};

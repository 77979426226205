import axios from "axios";

axios.defaults.xsrfCookieName = 'zeroleak_csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },

  credentials: "include",
  timeout: 30000,
  timeoutErrorMessage: 'Timeout'
});

export default instance;
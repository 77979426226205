import { createRouter, createWebHistory } from 'vue-router'
import TokenService from "@/services/token.service";

function loadView(view) {
  return () => import(`../views/${view}.vue`);
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login'),
  },
  {
    path: '/register',
    name: 'Register',
    component: loadView('Register'),
  },
  {
    path: "/",
    redirect: "/events",
    component: loadView('Layout'),
    children: [
      {
        path: "/events",
        name: "Events",
        components: { default: loadView('Events') },
      },
      {
        path: "/events/:page",
        name: "EventsPage",
        components: { default: loadView('Events') },
        props: true,
      },
      {
        path: "/events/edit/:id",
        name: "EditEvent",
        components: { default: loadView('EditEvent') },
        props: true
      },
      {
        path: "/events/:event_id/websites/add",
        name: "AddEventWebsite",
        components: { default: loadView('AddWebsite') },
        props: true
      },
      {
        path: "/events/add",
        name: "AddEvent",
        components: { default: loadView('AddEvent') },
      },
      {
        path: "/websites",
        name: "Websites",
        components: { default: loadView('Websites') },
        props: true
      },
      {
        path: "/websites/:page",
        name: "WebsitesPage",
        components: { default: loadView('Websites') },
        props: true
      },
      {
        path: "/websites/edit/:id",
        name: "EditWebsite",
        components: { default: loadView('EditWebsite') },
        props: true
      },
      {
        path: "/websites/add",
        name: "AddWebsite",
        components: { default: loadView('AddWebsite') },
        props: true
      },
      {
        path: "/profile",
        name: "Profile",
        components: { default: loadView('Profile') }
      },
      {
        path: "/modules",
        name: "Modules",
        components: { default: loadView('Modules') }
      },
      {
        path: "/modules/edit/:id",
        name: "EditModules",
        components: { default: loadView('EditModules') },
        props: true,
      },
      {
        path: "/sources",
        name: "SourceLeak",
        components: { default: loadView('SourceLeak') }
      },
      {
        path: "/licenses",
        name: "License",
        components: { default: loadView('License') }
      },
      {
        path: "/seasons",
        name: "Season",
        components: { default: loadView('Season') }
      },
      {
        path: "/seasons/:id",
        name: "EditSeason",
        components: { default: loadView('EditSeason') },
        props: true
      },
      {
        path: "/partners",
        name: "Partner",
        components: { default: loadView('Partner') }
      },
    ]
  },
  {
    // will match everything
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: loadView('NotFound'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const status = TokenService.getStatus()
  const loggedIn = status ? status['loggedIn'] : false

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (loggedIn && to.path == '/login') {
    next('/')
  } else if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router

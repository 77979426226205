class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.refresh_token : null
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user ? user.access_token : null
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.access_token = token;
    // prevent save access_token or refresh_token
    // user.access_token = ''
    // end
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    // prevent save access_token or refresh_token
    // user.access_token = ''
    // user.refresh_token = ''
    // end
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  setStatus(status){
    localStorage.setItem("status", JSON.stringify(status));
  }

  getStatus() {
    return JSON.parse(localStorage.getItem("status"));
  }
}

export default new TokenService();
